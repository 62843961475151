.heroAdBtn { 
  width: 300px;
  text-align: center;
  position: absolute;
  bottom: 30px;
  left: 50%;
  margin-left: -150px;
}

 .heroBackgroundImg {
     height: 100%;
 }


 .heroAdImg {
  height: 100%;
  filter: sepia(1) saturate(1);

}

.heroAdImg:hover {
  animation: filter-animation 1s ease-in;
  animation-fill-mode: forwards;
}

@keyframes filter-animation {
   
  from {
      filter: sepia(1)  saturate(1);
    }
  
  to {
    filter: sepia(0)  saturate(1);
  }
}

/* .iframeFlyin {
  min-height: 1800px;
}

@media only screen and (max-width: 600px) {
  .iframeFlyin {
    min-height: 2300px;
  }
} */

.imgCenter {
  text-align: center;
  background-color: #222;
  width: 100%;
  padding: 50px;
}

.imgCenter img {
  width: 100%;
  max-width: 500px;
}


.heroAdBackground {
  width: 100%;
  height: 100%;
}

 @media only screen and (max-width: 600px) {
    .heroBackgroundImg {
      margin-left: -15%;
    }
  }

  @media only screen and (min-width: 601px) {
    .heroBackgroundImg {
      margin: auto;
    }
  }

 .heroText {
     padding-top: 200px;
     padding-left: 20px;
 }