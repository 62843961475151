/* .shellContainer {
    
} */

.podcastShell {
    background: #dcdcdc;
    padding: 40px 0;
}

.podcastCard {
    margin: 20px 0;
}
.podcastFlyer {
    filter: sepia(1) saturate(1);
}

.podcastFlyer:hover {
    animation: filter-animation 1s ease-in;
    animation-fill-mode: forwards;
}

@keyframes filter-animation {
   
    from {
        filter: sepia(1)  saturate(1);
      }
    
    to {
      filter: sepia(0)  saturate(1);
    }
  }